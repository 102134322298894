.styled-tabs {
  background-color: #0000000F;
  border-radius: 40px;
  max-height: 42px;
  width: max-content;
  margin: 0 auto;

  align-items: center;

  .MuiTabs-scroller {
    .MuiTabs-indicator {
      display: none;
    }

    .MuiTabs-flexContainer {
      padding: 5px;

      button {
        height: 32px;
        min-height: 32px;
        border-radius: 32px;
        padding: 7.5px 12px;

        &.Mui-selected {
          color: #fff;
          background-color: #263141;
        }
      }
    }
  }
}
.tabs {
  background-color: #0000000F;
  border-radius: 40px;
  max-height: 42px;
  width: max-content;


  .MuiTabs-scroller {
    .MuiTabs-indicator {
      display: none;
    }

    .MuiTabs-flexContainer {
      padding: 5px;

      button {
        height: 32px;
        min-height: 32px;
        border-radius: 32px;
        padding: 7.5px 12px;

        &.Mui-selected {
          color: #fff;
          background-color: #263141;
        }
      }
    }
  }
}
.tabpanel-class {
  div {

    .MuiBox-root {
      border: none;
    }
  }
}

.sticky-Tab {
  position: sticky;
  background-color: #fff;
  // margin-left: -32px;
  // margin-right: -32px;
  // padding-left: 32px;
  // padding-right: 32px;
  z-index: 1101;
  &.no-margin{
    margin: 0;
  }
}