.scroll-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

::-ms-reveal {
  display: none;
}

.sidebar-drawer {
  .MuiListItemIcon-root {
    min-width: 20px !important;
  }
}

.sidebar-link {
  text-decoration: none;
  width: 100%;

  .MuiListItemIcon-root {
    filter: brightness(0) saturate(100%) invert(99%) sepia(50%) saturate(492%) hue-rotate(344deg) brightness(110%) contrast(101%);
  }
}

.sidebar-link-active {
  background: #00000033;
  border-radius: 8px;
}

.sidebar-icon {
  filter: brightness(0) saturate(100%) invert(99%) sepia(50%) saturate(492%) hue-rotate(344deg) brightness(110%) contrast(101%);
}

//Loader
.custom-loader-wrapper {
  display: flex;
  position: relative;
  justify-content: center;

  .custom-loader {
    width: 48px;
    height: 48px;
    border: 5px solid #e6e6e6;
    border-bottom-color: #0157de;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

//toastify
.toastify {
  border-radius: 8px !important;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  min-width: 350px;
  margin-left: auto;
  padding-right: 34px;
  display: flex !important;
  align-items: center;
  text-transform: capitalize;

  .toastify-avatar {
    filter: invert(100%) sepia(0%) saturate(580%) hue-rotate(186deg) brightness(106%) contrast(101%);
    margin: 0px 8px 0 0;
    height: 22px;
    width: 22px;
  }

  .toast-close {
    position: absolute;
    right: 12px;
    top: 12px;
  }
}

.popover-menuitem {
  gap: 8px;
}

.text-with-line {
  margin: 100px auto;
  text-align: center;
  color: black;
  max-width: 100%;
  position: relative;
  padding: 6px 0;

  &:before {
    content: "";
    display: block;
    width: 45%;
    height: 0.5px;
    background: #dfe8f2;
    left: 0px;
    top: 50%;
    position: absolute;
  }

  &:after {
    content: "";
    display: block;
    width: 45%;
    height: 0.5px;
    background: #dfe8f2;
    right: 0px;
    top: 50%;
    position: absolute;
  }
}

.table-paper-container {
  width: 100%;
  margin-bottom: 16px;
  border-radius: 16px;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  overflow: auto;
}

//breadcrumbs

.MuiBreadcrumbs-li {
  a {
    cursor: pointer;
  }
}

.container-dropzone {
  height: 86px;
  width: 86px;
}

.calendarElement {
  background-color: #fff;

  .rdrDateRangePickerWrapper {
    border-radius: 8px;
    overflow: hidden;

    .rdrDefinedRangesWrapper {
      display: contents;
    }
  }
}

.ingredient-list {
  display: flex;
  flex-direction: row;
  align-items: center;

  li {
    &::before {
      // add the new bullet point
      content: "";
      height: 3px;
      width: 3px;
      margin-right: 3px;
      margin-left: 4px;
      background-color: #8e99ac;
    }

    text-indent: 4px; // this is only needed if you want to align wrapped text
    display: flex;
    align-items: center;

    &:first-of-type {
      text-indent: 0;

      &::before {
        content: none; // this will hide the bullet for the first item
      }
    }
  }
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;

  .image {
    opacity: 1;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s ease;
    backface-visibility: hidden;
  }

  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    background-color: #fff;
    border-radius: 8px;
  }

  .text {
    background-color: #000;
    color: white;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 12px;
  }

  &:hover {
    .image {
      opacity: 0.3;
    }

    .middle {
      opacity: 1;
    }
  }
}

.fade-in {
  opacity: 0;
  transform: translateX(10px);
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.sidebar-tooltip {
  .MuiTooltip-tooltip {
    margin-left: 6px !important;
    background-color: #FFFCEB;
    color: #5E7360;
    border-radius: 0px;
    box-shadow: rgba(0, 0, 0, 0.15) 1px 1px 7px;
    text-transform: uppercase;
  }
}

.submenu-popover {
  .MuiPaper-root {
    padding: 8px 0;
    background-color: #5E7360;
    border-radius: 0px;
    margin-left: 12px;
    margin-top: 4px;
    box-shadow: rgba(0, 0, 0, 0.15) 1px 1px 7px;

    .MuiListItemText-root {
      margin-right: 12px;
    }

    .MuiListItemIcon-root {
      min-width: 30px;
    }
  }
}

.profile-popover {
  .MuiPaper-root {
    border-radius: 15px;
    margin-left: 20px;
    border: 1px solid #E9EDF2;
    margin-top: 4px;
    box-shadow: 0px 1px 3px 0px #1F23281F,  0px 8px 24px 0px #424A531F;
  }
}